<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">年度プログラム一覧</li>
    </ol>
  </nav>
  <div class="container">
    <h2>{{ props.fiscal_year }}年度 {{ category }}一覧</h2>
    <span>申込先YMCA：
      <router-link 
        :to="{ 
          name: 'EventList', 
          params: { fiscal_year: props.fiscal_year, category: props.category }, 
          query: { ...$route.query, shop: undefined } 
        }"
        :class="['event_list', { active: !$route.query.shop }]"
      >
        すべて
      </router-link>
      <template v-for="event in uniqueShops" :key="event.shop">｜
        <router-link 
          :to="{ 
            name: 'EventList', 
            params: { fiscal_year: props.fiscal_year, category: props.category }, 
            query: { ...$route.query, shop: event.shop } 
          }"
          :class="['event_list', { active: Number($route.query.shop) === event.shop }]"
        >
          {{ event.department?.name }}
        </router-link>
      </template>
    </span>
    <br>
    <span>種類：
      <router-link 
        :to="{ 
          name: 'EventList', 
          params: { fiscal_year: props.fiscal_year, category: props.category }, 
          query: { ...$route.query, kind: undefined } 
        }"
        :class="['event_list', { active: !$route.query.kind }]"
      >
        すべて
      </router-link>
      <template v-for="event in uniqueKindTypes" :key="event.kindType.name">｜
        <router-link 
          :to="{ 
            name: 'EventList', 
            params: { fiscal_year: props.fiscal_year, category: props.category }, 
            query: { ...$route.query, kind: event.kindType.id } 
          }"
          :class="['event_list', { active: Number($route.query.kind) === event.kindType.id }]"
        >
          {{ event.kindType.name }}
        </router-link>
      </template>
    </span>
    <div class="program-list-inner mt-4"  v-if="events && events.length > 0">
      <div class="table-wrap table-responsive">
        <table class="normal mgT20 table mb-0" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03 tblTit">種類</th>
              <th class="tblBg03 list-nowrap">プログラム名</th>
              <th class="tblBg03 list-nowrap">対象</th>
              <th class="tblBg03 list-nowrap" v-if="category != '通年プログラム'">開催日</th>
              <th class="tblBg03 list-nowrap">開催場所</th>
              <th class="tblBg03 list-nowrap" v-if="category != '通年プログラム'">基本費用</th>
            </tr>
            <template v-for="event in events">
              <tr>
                <td class="list-nowrap">{{ event?.kindType ? event?.kindType.name : ''}}</td>
                <td class="list-nowrap">
                  <router-link :to="{ name: 'EventDetail', params: { path: event.path } }">
                    <span v-html="lineBreak(event?.title)"></span>
                  </router-link>
                </td>
                <td class="list-nowrap">{{ minTarget(event)?.name }} ～ {{ maxTarget(event)?.name }}</td>
                <td v-if="category != '通年プログラム'">
                  <template v-for="schedule in event.schedules" :key="schedule.id">
                    <div class="row m-0">
                      <div class="form-check">
                        <template v-if="schedule.startAt && schedule.endAt && schedule.startAt === schedule.endAt">
                          <label class="form-check-label list-nowrap" :for="`schedule-${schedule.id}`">{{
    toDateSlash(schedule.startAt) }} </label>
                        </template>
                        <template v-else-if="schedule.startAt && schedule.endAt">
                          <label class="form-check-label list-nowrap" :for="`schedule-${schedule.id}`">{{
    toDateSlash(schedule.startAt) }}{{ schedule.endAt ? '〜' + toDateSlash(schedule.endAt) : ''
                            }} </label>
                        </template>
                      </div>
                    </div>
                  </template>
                </td>
                <td v-if="event" class="list-nowrap">{{ event.destination }}</td>
                <td v-if="category != '通年プログラム'" class="list-nowrap">
                  <span v-if="event?.priceDetail">
                    {{ event?.priceDetail.toLocaleString() }}{{ event?.priceDetail ? '円' : '' }}
                  </span>
                  <span v-if="!event?.priceDetail">
                    0円
                  </span>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      <div class="btn-area">
        <button class="btn btn-back" @click="$router.push({name: 'Top'})">TOPへ戻る</button>
      </div>
    </div>
    <div v-else-if="!useLoadingStore().isLoading">
      <br><p class="text-center">現在公開されているプログラムはありません</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { TEvent } from '@/types/event'
import { TCategoryType } from '@/types/search'
import { TTargetType } from '@/types/search'
import { toDateSlash} from '@/js/util'
import { handleErrors } from '@/config/handleErrors'
import { useLoadingStore } from '@/stores/loading'
import { API } from '../../api'

const events = ref<TEvent[]>([])
const allEvents = ref<TEvent[]>([])
const category = ref<string | undefined>('')
const minYear: number = new Date().getFullYear()

// const props = defineProps<{
//   fiscal_year: string,
//   category: string,
// }>()
const props = defineProps<{
  fiscal_year: string,
  category: string | null,
  shop: number | null,
  kind: number | null,
}>();

useLoadingStore().start()
const router = useRouter()
const route = useRoute()

onMounted(async () => {
  try {
    const minFiscalYear: number = minYear % 100
    const fiscalYear2dig: number = Number(props.fiscal_year) % 100
    if (!fiscalYear2dig || fiscalYear2dig > minFiscalYear) router.push({ name: 'Top'})
    const { categoryTypes: categoryTypes } = await TEvent.fetchCategoryTypes()
    let categoryId: number | undefined | null = null
    if (props.category !== '') categoryId = categoryTypes.find(s => s.id === Number(props.category))?.id
    if (categoryId === undefined) router.push({ name: 'Top'})
    if (categoryId !== null) category.value = categoryTypes.find(s => s.id === categoryId)?.name
    const shop = route.query.shop ? Number(route.query.shop) : null
    const kind = route.query.kind ? Number(route.query.kind) : null
    const { events: fetchEvent, allEvents: fetchAllEvents }
      = await TEvent.fetchFiscalYearPrograms({ fiscalYear: fiscalYear2dig, category: categoryId, shop:shop, kind:kind })
    events.value = fetchEvent
    allEvents.value = fetchAllEvents
    useLoadingStore().stop()
  } catch (e: any) {
    console.error(e)
    handleErrors(e)
  }
})

const minTarget = (event: TEvent) => {
  if (event && event.targetType.length > 0) {
    return event.targetType.reduce((min: TTargetType, current: TTargetType) => {
      return min.id < current.id ? min : current;
    })
  }
}

const maxTarget = (event: TEvent) => {
  if (event && event.targetType.length > 0) {
    return event.targetType.reduce((max: TTargetType, current: TTargetType) => {
      return max.id > current.id ? max : current;
    })
  }
}

const lineBreak = (name: string) => {
  name = name.replace(
    /([（(])(?!(日|月|火|水|木|金|土)([A-C])?[）)])/g, 
    '<br>$1'
  )
  name = name.replace(
    /([（(](日|月|火|水|木|金|土)([A-C])?[）)])/g, 
    '$1'
  )
  name = name.replace(/(3days|３ｄａｙｓ)/g, '$1<br>')
  name = name.replace(/(1期|１期)/g, '$1<br>')
  name = name.replace(/(2期|２期)/g, '$1<br>')
  name = name.replace(/(3期|３期)/g, '$1<br>')
  name = name.replace(/(「[A-Za-z0-9\s~\-]+」)/u, '<br>$1')
  return name
}

// データ取得関数
const fetchEvents = async () => {
  try {
    useLoadingStore().start()

    // const fiscalYear = Number(route.params.fiscal_year); // 必須
    const minFiscalYear: number = minYear % 100
    const fiscalYear: number = Number(props.fiscal_year) % 100
    if (!fiscalYear || fiscalYear > minFiscalYear) router.push({ name: 'Top'})

    const category = route.params.category ? Number(route.params.category) : null // オプション
    const shop = route.query.shop ? Number(route.query.shop) : null
    const kind = route.query.kind ? Number(route.query.kind) : null

    // `fetchFiscalYearPrograms` を呼び出す
    const { events: fetchedEvents } = await TEvent.fetchFiscalYearPrograms({
      fiscalYear,
      category,
      shop,
      kind,
    });
    events.value = fetchedEvents // データを設定
    useLoadingStore().stop()
  } catch (e: any) {
    useLoadingStore().stop()
    handleErrors(e)
    console.error('Error fetching events:', e)
  }
}

// URL パラメータの変更を監視してデータを取得
watch(() => route.params, fetchEvents, { immediate: true })

const uniqueShops = computed(() => {
  const seen = new Set()
  return allEvents.value
    .filter(event => {
      if (event.shop && !seen.has(event.shop)) {
        seen.add(event.shop)
        return true
      }
      return false
    })
})

const uniqueKindTypes = computed(() => {
  const seen = new Set()
  return allEvents.value.filter(event => {
    if (event.kindType?.name && !seen.has(event.kindType.name)) {
      seen.add(event.kindType.name)
      return true
    }
    return false
  })
  .sort((a, b) => a.kindType.id - b.kindType.id)
})

</script>
