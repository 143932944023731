import { createRouter, createWebHistory, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouterScrollBehavior } from 'vue-router'
import Top from '../views/Top.vue'
import NoticeDetail from '../views/notices/detail.vue'
import SearchIndex from '../views/search/index.vue'
import EntryIndex from '../views/entry/index.vue'
import EntryMail from '../views/entry/mail.vue'
import EntryProfile from '../views/entry/profile.vue'
import EntryProfileChild from '../views/entry/profileChild.vue'
import EntryProfileConfirm from '../views/entry/profileConfirm.vue'
import EntryComplete from '../views/entry/complete.vue'
import LoginIndex from '../views/login/index.vue'
import LoginImpersonate from '../views/login/impersonate.vue'
import MypageIndex from '../views/mypage/index.vue'
import MypageWithdrawal from '../views/mypage/withdrawal.vue'
import MypageEventSchedule from '../views/mypage/schedule.vue'
import MypageEventFavorite from '../views/mypage/favorite.vue'
import MypageEventDetail from '../views/mypage/event_detail.vue'
import MypageEventDetailAfter from '../views/mypage/event_detail_after.vue'
import MypageProfileIndex from '../views/mypage/profile/index.vue'
import MypageProfileComplete from '../views/mypage/profile/complete.vue'
import MypagePasswordForm from '../views/mypage/password/form.vue'
import MypagePasswordComplete from '../views/mypage/password/complete.vue'
import EventDetail from '../views/event/detail.vue'
import EventEntry from '../views/event/entry.vue'
import EventWaiting from '../views/event/waiting.vue'
import EventConfirm from '../views/event/confirm.vue'
import EventComplete from '../views/event/complete.vue'
import EventList from '../views/event/list.vue'
import StaticBrowser from '../views/static/browser.vue'
import StaticCancel from '../views/static/cancel.vue'
import StaticAnnualCancel from '../views/static/annualCancel.vue'
import StaticFaq from '../views/static/faq.vue'
import StaticPrivacy from '../views/static/privacy.vue'
import StaticRecommendedEnvironment from '../views/static/Recommended environment.vue'
import StaticRule from '../views/static/rule.vue'
import StaticEntry from '../views/static/entry.vue'
import StaticConfirmation from '../views/static/confirmation.vue'
import StaticAnnualConfirmation from '../views/static/annualConfirmation.vue'
import StaticFriendshipFund from '../views/static/friendshipFund.vue'
import PasswordIndex from '../views/password/index.vue'
import PasswordMail from '../views/password/mail.vue'
import PasswordForm from '../views/password/form.vue'
import PasswordComplete from '../views/password/complete.vue'
import InquiryIndex from '../views/inquiry/index.vue'
import InquiryComplete from '../views/inquiry/complete.vue'
import RequestIndex from '../views/request/index.vue'
import RequestComplete from '../views/request/complete.vue'
import Expired from '../views/errors/expired.vue'
import Error from '../views/errors/error.vue'
import NotFound from '../views/errors/404.vue'
import { useMemberStore } from '@/stores/member'
import { setSeo } from '@/js/util'

const routes = [
  { path: '/', name: 'Root', component: Top},
  { path: '/top', name: 'Top', component: Top},
  { path: '/notice/detail/:path', name: 'NoticeDetail', component: NoticeDetail, props: true},
  { path: '/login/index', name: 'LoginIndex', component: LoginIndex },
  { path: '/login/impersonate', name: 'loginImpersonate', component: LoginImpersonate },
  { path: '/search/index', name: 'SearchIndex', component: SearchIndex },
  { path: '/entry/index', name: 'EntryIndex', component: EntryIndex },
  { path: '/entry/mail', name: 'EntryMail', component: EntryMail },
  { path: '/entry/profile', name: 'EntryProfile', component: EntryProfile },
  { path: '/entry/profile/child', name: 'EntryProfileChild', component: EntryProfileChild },
  { path: '/entry/profile/confirm', name: 'EntryProfileConfirm', component: EntryProfileConfirm },
  { path: '/entry/complete', name: 'EntryComplete', component: EntryComplete }, 
  { path: '/password/index', name: 'PasswordIndex', component: PasswordIndex },
  { path: '/password/mail', name: 'PasswordMail', component: PasswordMail },
  { path: '/password/form', name: 'PasswordForm', component: PasswordForm },
  { path: '/password/complete', name: 'PasswordComplete', component: PasswordComplete },
  { path: '/mypage/index', name: 'MypageIndex', component: MypageIndex, meta: { requiresAuth: true }},
  { path: '/mypage/withdrawal', name: 'MypageWithdrawal', component: MypageWithdrawal, meta: { requiresAuth: true }},
  { path: '/mypage/event_detail', name: 'MypageEventDetail', component: MypageEventDetail, meta: { requiresAuth: true }},
  { path: '/mypage/schedule', name: 'MypageEventSchedule', component: MypageEventSchedule, meta: { requiresAuth: true }},
  { path: '/mypage/favorite', name: 'MypageEventFavorite', component: MypageEventFavorite, meta: { requiresAuth: true }},
  // { path: '/mypage/event_detail_after', name: 'MypageEventDetailAfter', component: MypageEventDetailAfter, meta: { requiresAuth: true }},
  { path: '/mypage/profile/index', name: 'MypageProfileIndex', component: MypageProfileIndex, meta: { requiresAuth: true }},
  { path: '/mypage/profile/complete', name: 'MypageProfileComplete', component: MypageProfileComplete, meta: { requiresAuth: true }},
  { path: '/mypage/password/form', name: 'MypagePasswordForm', component: MypagePasswordForm, meta: { requiresAuth: true }},
  { path: '/mypage/password/complete', name: 'MypagePasswordComplete', component: MypagePasswordComplete, meta: { requiresAuth: true }},
  { path: '/event/detail/:path', name: 'EventDetail', component: EventDetail, props: true},
  { path: '/event/entry', name: 'EventEntry', component: EventEntry, meta: { requiresAuth: true }},
  { path: '/event/waiting', name: 'EventWaiting', component: EventWaiting, meta: { requiresAuth: true }},
  { path: '/event/confirm', name: 'EventConfirm', component: EventConfirm, meta: { requiresAuth: true }},
  { path: '/event/complete', name: 'EventComplete', component: EventComplete, meta: { requiresAuth: true }},
  // { path: '/all-program/:fiscal_year/:category?/:shop?/:kind?', name: 'EventList', component: EventList, props: true },
  {
    path: '/all-program/:fiscal_year/:category?',
    name: 'EventList',
    component: EventList,
    props: (route) => ({
      fiscal_year: route.params.fiscal_year,
      category: route.params.category || null,
      shop: route.query.shop ? Number(route.query.shop) : null,
      kind: route.query.kind ? Number(route.query.kind) : null,
    }),
  },
  { path: '/static/browser', name: 'StaticBrowser', component: StaticBrowser },
  { path: '/static/cancel', name: 'StaticCancel ', component: StaticCancel },
  { path: '/static/annual-cancel', name: 'StaticAnnualCancel ', component: StaticAnnualCancel },
  { path: '/static/faq', name: 'StaticFaq ', component: StaticFaq },
  { path: '/static/privacy', name: 'StaticPrivacy ', component: StaticPrivacy },
  { path: '/static/recommendedenvironment', name: 'StaticRecommendedEnvironment', component: StaticRecommendedEnvironment },
  { path: '/static/rule', name: 'StaticRule', component: StaticRule },
  { path: '/static/entry', name: 'StaticEntry', component: StaticEntry },
  { path: '/static/confirmation', name: 'StaticConfirmation', component: StaticConfirmation },
  { path: '/static/annual-confirmation', name: 'StaticAnnualConfirmation', component: StaticAnnualConfirmation },
  { path: '/static/friendshipfund', name: 'StaticFriendshipFund', component: StaticFriendshipFund },
  { path: '/inquiry/index', name: 'InquiryIndex', component: InquiryIndex },
  { path: '/inquiry/complete', name: 'InquiryComplete', component: InquiryComplete },
  { path: '/request/index', name: 'RequestIndex', component: RequestIndex },
  { path: '/request/complete', name: 'RequestComplete', component: RequestComplete },
  { path: '/errors/expired', name: 'Expired', component: Expired },
  { path: '/errors/error', name: 'Error', component: Error },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]


const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        })
      })
    } else {
      if (to.name === 'EventConfirm') {
        return
      }
      if (to.name === 'SearchIndex' && Object.keys(to.query).length > 0) {
        // return false
        if (to.hash) {
          return {
            el: to.hash,
            behavior: 'smooth',
          }
        }
      }
      return { left: 0, top: 0 }
    }
  }
})

router.afterEach(() => {
  const collapseNavItems = document.querySelectorAll('.collapse .nav li')
  collapseNavItems.forEach(item => {
    // item.addEventListener('click', () => {
      const collapse = item.closest('.collapse')
      if (collapse) {
        document.body.style.height = '';
        document.body.style.overflow = '';
        collapse.classList.remove('show')
      }
    // })
  })
})

router.beforeEach((to, from, next) => {
  setSeo(to.name?.toString())
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!useMemberStore().isLoggedIn) {
      next('/login/index');
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router