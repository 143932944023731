import { API } from '../api'
import { getUniqueStr } from '@/js/util'

const resource = '/api/member/'

export class TMember {
  id: number
  memberNumber: number
  mailAddress: string
  password: string
  mailVerifiedAt: string
  lastName: string
  firstName: string
  lastNameKana: string
  firstNameKana: string
  nickname: string
  birthday: Date
  year: string | null
  month: string | null
  day: string | null
  gender: number
  phoneNumber: string
  postalCode: string
  prefecture: string
  city: string
  streetAddress: string
  buildingName: string
  attendanceCount:  number
  isMailMagazine: boolean
  lastLogin: Date
  // 参加するかで使う
  willAttend: boolean
  valid: any
  count: number
  memberships: number[]

  constructor (data: any) {
    this.id = data.id
    this.memberNumber = data.member_number
    this.mailAddress  = data.mail_address
    this.password = data.password
    this.mailVerifiedAt  = data.mail_verified_at
    this.lastName  = data.last_name
    this.firstName  = data.first_name
    this.lastNameKana  = data.last_name_kana
    this.firstNameKana  = data.first_name_kana
    this.nickname  = data.nickname
    this.birthday  = data.birthday
    this.year = data.birthday ? new Date(data.birthday).getFullYear().toString() : null
    this.month = data.birthday ? (new Date(data.birthday).getMonth() + 1).toString() : null
    this.day = data.birthday ? new Date(data.birthday).getDate().toString() : null
    this.gender = data.gender
    this.phoneNumber  = data.phone_number
    this.postalCode  = data.postal_code
    this.prefecture  = data.prefecture
    this.city  = data.city
    this.streetAddress  = data.street_address
    this.buildingName  = data.building_name
    this.attendanceCount  = data.attendance_count
    this.isMailMagazine  = data.is_mail_magazine
    this.lastLogin  = data.last_login
    this.willAttend = false
    this.valid = null
    this.count = data.count
    this.memberships = ('memberships' in data) ? data.memberships : []
  }

  static fromData(data: any): TMember {
    return new TMember({
      id: data.id,
      mailAddress: data.mailAddress,
      mailVerifiedAt: data.mailVerifiedAt,
      lastName: data.lastName,
      firstName: data.firstName,
      lastNameKana: data.lastNameKana,
      firstNameKana: data.firstNameKana,
      nickname: data.nickname,
      birthday: new Date(data.birthday),
      year: data.year,
      month: data.month,
      day: data.day,
      gender: data.gender,
      phoneNumber: data.phoneNumber,
      postalCode: data.postalCode,
      prefecture: data.prefecture,
      city: data.city,
      streetAddress: data.streetAddress,
      buildingName: data.buildingName,
      attendanceCount: data.attendanceCount,
      isMailMagazine: data.isMailMagazine,
      lastLogin: new Date(data.lastLogin),
      willAttend: data.willAttend,
      valid: data.valid,
      count: data.count
    })
  }

  static createEmptyMember(count?: number): TMember {
    return new TMember({
      id: null,
      mailAddress: '',
      password: '',
      mailVerifiedAt: '',
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      nickname: '',
      birthday: null,
      year: null,
      month: null,
      day: null,
      gender: 1,
      phoneNumber: '',
      postalCode: '',
      prefecture: '',
      city: '',
      streetAddress: '',
      buildingName: '',
      attendanceCount: 0,
      isMailMagazine: false,
      lastLogin: '',
      willAttend: false,
      valid: null,
      count: count || 0
    })
  }

  static fetch ({ id } : { id: number }) {
    const params = { id: id }
    return API.get(resource.concat('detail'), { params }).then((r) => { 
      console.log(r.data) 
      return { event: new TMember(r.data.event),
        }
    } )
  }

  static fetchFamilyMember ({ id } : { id: number }) {
    const params = { id: id }
    return API.get(resource.concat('family'), { params }).then((r) => { 
      console.log(r.data) 
      return { 
        parent: new TMember(r.data.parent),
        children: (r.data.children as any[]).map(data => new TMember(data)),
      }
    })
  }

  static async updateFamily ({parent, children }: {parent: TMember | undefined, children: TMember[]}) {
    const params = {parent: parent, children: children}
    const response = await API.put(resource.concat('update'), params)
    if (response.data.status === 'failed') {
      alert(response.data.message);
      return 'failed'
    }
    return 'success'
  }

  static async storeEntry ({parent, children}: {parent: TMember | undefined, children: TMember[]}) {
    const params = { parent: parent, children: children}
    return await API.post('/api/entry/profile', params)
    // if (response.data.status === 'failed') {
    //   alert(response.data.message);
    //   return 'failed'
    // }
    // return 'success'
  }
}
