<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/Top">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">プログラム検索</li>
    </ol>
  </nav>
  <div class="search--area">
    <h2>プログラム検索</h2>
    <div class="search--table px-3 px-lg-0">
      <div class="search--inner">
        <table class="table table-bordered mb-0">
          <tr>
            <th class="align-middle col-3 col-md-2">種類</th>
            <td>
              <div class="d-md-flex flex-wrap justify-content-start">
                <div class="mx-2 form-check">
                  <input type="radio" name="kindCheck" class="form-check-input" id="kindCheck-0" :value="0"
                    v-model="searchKind" checked>
                  <label class="form-check-label" for="kindCheck-0">全て</label>
                </div>
                <div class="mx-2 form-check" v-for="kind in kindTypes" :key="kind.id">
                  <input type="radio" name="kindCheck" class="form-check-input" :id="`kindCheck-${kind.id}`"
                    v-model="searchKind" :value="kind.id">
                  <label class="form-check-label" :for="`kindCheck-${kind.id}`">{{ kind.name }}</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="align-middle">申込先YMCA</th>
            <td>
              <div class="d-md-flex flex-wrap justify-content-start">
                <div class="mx-2 form-check">
                  <input type="radio" name="shopCheck" class="form-check-input" id="shopCheck-0" :value="0"
                    v-model="searchYmca" checked>
                  <label class="form-check-label" for="shopCheck-0">全て</label>
                </div>
                <template v-for="shop in shopTypes" :key="shop.id">
                  <div class="mx-2 form-check" v-if="shop.isDisplay">
                    <input type="radio" name="shopCheck" class="form-check-input" :id="`shopCheck-${shop.id}`"
                      :value="shop.id" v-model="searchYmca">
                    <label class="form-check-label" :for="`shopCheck-${shop.id}`">{{ shop.name }}</label>
                  </div>
                </template>
              </div>
            </td>
          </tr>
          <tr>
            <th class="align-middle">拠点</th>
            <td>
              <div class="d-md-flex flex-wrap justify-content-start">
                <div class="mx-2 form-check">
                  <input type="radio" name="baseCheck" class="form-check-input" id="baseCheck-0" :value="0"
                    v-model="searchBase" checked>
                  <label class="form-check-label" for="baseCheck-0">全て</label>
                </div>
                <div class="mx-2 form-check" v-for="base in baseTypes" :key="base.id">
                  <template v-if="base.isDisplay">
                    <input type="radio" name="baseCheck" class="form-check-input" :id="`baseCheck-${base.id}`"
                      :value="base.id" v-model="searchBase">
                  <label class="form-check-label" :for="`baseCheck-${base.id}`">{{ base.name }}</label>
                  </template>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="align-middle">カテゴリー</th>
            <td>
              <div class="d-md-flex flex-wrap">
                <div class="mx-2 form-check" v-for="cat in categoryTypes" :key="cat.id">
                  <input type="checkbox" class="form-check-input" :id="`categoryCheck-${cat.id}`" :value="cat.id"
                    v-model="searchCategories">
                  <label class="form-check-label" :for="`categoryCheck-${cat.id}`">{{ cat.name }}</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="align-middle">対象年齢</th>
            <td>
              <div class="d-md-flex">
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="ageCheck1" :value="1" v-model="searchTargets">
                  <label class="form-check-label" for="ageCheck1">0~2歳</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="ageCheck2" :value="2" v-model="searchTargets">
                  <label class="form-check-label" for="ageCheck2">未就学児</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="ageCheck3" :value="3" v-model="searchTargets">
                  <label class="form-check-label" for="ageCheck3">小学生</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="ageCheck4" :value="4" v-model="searchTargets">
                  <label class="form-check-label" for="ageCheck4">中学生</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="ageCheck5" :value="5" v-model="searchTargets">
                  <label class="form-check-label" for="ageCheck5">高校生</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="ageCheck6" :value="6" v-model="searchTargets">
                  <label class="form-check-label" for="ageCheck6">大学生・社会人</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="ageCheck7" :value="7" v-model="searchTargets">
                  <label class="form-check-label" for="ageCheck7">成人</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="align-middle">料金</th>
            <td>
              <div class="d-md-flex">
                <div class="mx-2 form-check">
                  <input type="radio" name="is_payCheck" class="form-check-input" id="is_payCheck0" :value="0"
                    v-model="searchPrice" checked>
                  <label class="form-check-label" for="is_payCheck0">全て</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="radio" name="is_payCheck" class="form-check-input" id="is_payCheck1" :value="1"
                    v-model="searchPrice">
                  <label class="form-check-label" for="is_payCheck1">無料</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="radio" name="is_payCheck" class="form-check-input" id="is_payCheck2" :value="2"
                    v-model="searchPrice">
                  <label class="form-check-label" for="is_payCheck2">有料</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="align-middle">空き状況</th>
            <td>
              <div class="d-md-flex">
                <div class="mx-2 form-check">
                  <input type="radio" name="availabilityCheck" class="form-check-input" id="availabilityCheck0"
                    :value="0" v-model="searchVacancy" checked>
                  <label class="form-check-label" for="availabilityCheck0">全て</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="radio" name="availabilityCheck" class="form-check-input" id="availabilityCheck1"
                    :value="1" v-model="searchVacancy">
                  <label class="form-check-label" for="availabilityCheck1">空き有り</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="radio" name="availabilityCheck" class="form-check-input" id="availabilityCheck2"
                    :value="2" v-model="searchVacancy">
                  <label class="form-check-label" for="availabilityCheck2">空き無し</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="align-middle">日程</th>
            <td>
              <div class="d-md-flex">
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="program_dateCheck1" :value="1"
                    v-model="searchSchedules" checked>
                  <label class="form-check-label" for="program_dateCheck1">日帰り</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="program_dateCheck2" :value="2"
                    v-model="searchSchedules">
                  <label class="form-check-label" for="program_dateCheck2">1泊</label>
                </div>
                <div class="mx-2 form-check">
                  <input type="checkbox" class="form-check-input" id="program_dateCheck3" :value="3"
                    v-model="searchSchedules">
                  <label class="form-check-label" for="program_dateCheck3">2泊以上</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="align-middle">キーワード</th>
            <td>
              <div class="mx-2">
                <Field type="text" class="form-control" name="searchKeyword" />
                <div class="text-danger form-error">{{ errors.searchKeyword }}</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="mx-auto mt-4 btn-area">
        <button type="button" class="btn btn-primary btn-search" @click="submitSearch()">検索</button>
        <button type="button" class="btn btn-back" @click="handleClear()">クリア</button>
      </div>
    </div>
  </div>

  <div class="container" v-if="paginate" id="results">
    <div class="search-results">
      <dl class="search-results-inner">
        <dt>検索結果：</dt>
        <dd><span>{{ paginate.total }}</span>件</dd>
        <dd><span>{{ paginate.from }}</span>〜<span>{{ paginate.to }}</span>件表示中</dd>
      </dl>
    </div>
    <div class="top--section latestWorks mb-3">
      <template v-for="event in events" :key="event.id">
        <div class="latestWorks-inner result-type" v-if="event.images && event.images.length > 0">
          <img v-if="event.images && event.images.length > 0" :src="event.images[0].url" width="100%">
          <div class="latestWorks-inner--textarea">
            <ul class="tag-list">
              <li class="tag-list-item tag-full">{{ event.categoryType.name }}</li>
              <li class="tag-list-item tag-full">{{ event.kindType.name }}</li>
              <li class="tag-list-item" v-for="membership in displayMembership(event)" :key="membership.id">{{
                    membership.name }}</li>
              <li class="tag-list-item tag-paid">{{ event.priceDetail ? '有料' : '無料' }}</li>
              <li class="badge bg-primary">{{ receptionStatus(event.participants, event) }}</li>
            </ul>
            <div class="workTitle">
              <router-link :to="{ name: 'EventDetail', params: { path: event.path } }" target="_blank">{{ event.title
                }}</router-link>
            </div>
            <ul class="description">
              <li><img src="@images/icon-user-black2.svg" alt="対象">{{ targetRage(event.eventTargets) }}</li>
              <li><img src="@images/icon-salary-black.svg" alt="給料">{{ event.priceDetail ?
                    event.priceDetail.toLocaleString().concat('円(税込)') : '無料' }}</li>
              <template v-if="event.destination">
                <li class="icon-geo"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                  </svg>{{ event.destination }}</li>
              </template>
              <template v-for="schedule in event.eventSchedules" :key="schedule.id">
                <li><img src="@images/icon-star-black2.svg" alt="開催期間">
                  <template v-if="schedule.startAt === schedule.endAt">
                    {{ toDateSlash(schedule.startAt) }}
                  </template>
                  <template v-else>
                    {{ toDateSlash(schedule.startAt) }}{{ '〜' }}{{
                    toDateSlash(schedule.endAt) }}
                  </template>
                </li>
              </template>
              <li>
                <p v-html="event.content.replace(/\n/g, '<br>')"></p>
              </li>
            </ul>
          </div>
          <div class="btn-area mb-0" v-if="memberStore.isLoggedIn">
            <!-- <button class="btn btn-apply favo favo-active" v-if="memberStore.isLoggedIn && isFavorite(event)"> -->
            <button class="btn btn-apply favo favo-active" @click="handleFavorite(event, false)"
              v-if="isFavorite(event)">
              <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り済み</span>
            </button>
            <button class="btn btn-apply favo" @click="handleFavorite(event, true)" v-else>
              <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り登録</span>
            </button>
            <template v-if="isAttend(event)">
              <button disabled class="btn btn-apply entry-program" v-if="isApplied(event)"><span>応募済み</span><i
                  class="bi bi-chevron-right"></i></button>
              <button disabled class="btn btn-apply entry-program"
                v-else-if="receptionStatus(event.participants) === '満席'"><span>満席</span><i
                  class="bi bi-chevron-right"></i></button>
              <router-link class="btn btn-apply entry-program"
                :to="{ name: 'EventDetail', params: { path: event.path } }" target="_blank"
                v-else-if="receptionStatus(event.participants) === 'キャンセル待ち'"><span>キャンセル待ちする</span><i
                  class="bi bi-chevron-right"></i></router-link>
              <!-- <button disabled class="btn btn-apply entry-program" v-else-if="receptionStatus(event.participants) === '受付終了'"><span>受付終了</span><i
                class="bi bi-chevron-right"></i></button> -->
              <router-link class="btn btn-apply entry-program" v-else
                :to="{ name: 'EventDetail', params: { path: event.path } }" target="_blank"><span>予約する</span><i
                  class="bi bi-chevron-right"></i></router-link>
            </template>
          </div>
        </div>
        <div class="latestWorks-inner result-type no-image" v-else>
          <div class="latestWorks-inner--textarea">
            <ul class="tag-list">
              <li class="tag-list-item tag-full">{{ event.categoryType.name }}</li>
              <li class="tag-list-item tag-full">{{ event.kindType.name }}</li>
              <li class="tag-list-item" v-for="membership in displayMembership(event)" :key="membership.id">{{
                    membership.name }}</li>
              <li class="tag-list-item tag-paid">{{ event.priceDetail ? '有料' : '無料' }}</li>
              <li class="badge bg-primary">{{ receptionStatus(event.participants, event) }}</li>
            </ul>
            <div class="workTitle">
              <router-link :to="{ name: 'EventDetail', params: { path: event.path } }" target="_blank">{{ event.title
                }}</router-link>
            </div>
            <ul class="description">
              <li><img src="@images/icon-user-black2.svg" alt="対象">{{ targetRage(event.eventTargets) }}</li>
              <li><img src="@images/icon-salary-black.svg" alt="給料">{{ event.priceDetail ?
                    event.priceDetail.toLocaleString().concat('円(税込)') : '無料' }}</li>
              <template v-if="event.destination">
                <li class="icon-geo"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                  </svg>{{ event.destination }}</li>
              </template>
              <template v-for="schedule in event.eventSchedules" :key="schedule.id">
                <li><img src="@images/icon-star-black2.svg" alt="開催期間">
                  <template v-if="schedule.startAt === schedule.endAt">
                    {{ toDateSlash(schedule.startAt) }}<template
                      v-if="!event?.participants.find(p => p.eventCode === parseInt(schedule.eventCode))?.isApplicable">{{
                    displayReceptionStatus(event?.participants.find(p => p.eventCode === parseInt(schedule.eventCode)))
                    }}</template>
                  </template>
                  <template v-else>
                    {{ toDateSlash(schedule.startAt) }}{{ '〜' }}{{
                    toDateSlash(schedule.endAt) }}<template
                      v-if="!event?.participants.find(p => p.eventCode === parseInt(schedule.eventCode))?.isApplicable">{{
                    displayReceptionStatus(event?.participants.find(p => p.eventCode === parseInt(schedule.eventCode)))
                    }}</template>
                  </template>
                </li>
              </template>
              <li>
                <p v-html="event.content.replace(/\n/g, '<br>')"></p>
              </li>
            </ul>
          </div>
          <div class="btn-area mb-0" v-if="memberStore.isLoggedIn">
            <!-- <button class="btn btn-apply favo favo-active" v-if="memberStore.isLoggedIn && isFavorite(event)"> -->
            <button class="btn btn-apply favo favo-active" @click="handleFavorite(event, false)"
              v-if="isFavorite(event)">
              <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り済み</span>
            </button>
            <button class="btn btn-apply favo" @click="handleFavorite(event, true)" v-else>
              <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り登録</span>
            </button>
            <template v-if="isAttend(event)">
              <button disabled class="btn btn-apply entry-program" v-if="isApplied(event)"><span>応募済み</span><i
                  class="bi bi-chevron-right"></i></button>
              <button disabled class="btn btn-apply entry-program"
                v-else-if="receptionStatus(event.participants) === '満席'"><span>満席</span><i
                  class="bi bi-chevron-right"></i></button>
              <router-link class="btn btn-apply entry-program"
                :to="{ name: 'EventDetail', params: { path: event.path } }" target="_blank"
                v-else-if="receptionStatus(event.participants) === 'キャンセル待ち'"><span>キャンセル待ちする</span><i
                  class="bi bi-chevron-right"></i></router-link>
              <router-link class="btn btn-apply entry-program" v-else-if="!isApplied(event)"
                :to="{ name: 'EventDetail', params: { path: event.path } }" target="_blank"><span>予約する</span><i
                  class="bi bi-chevron-right"></i></router-link>
            </template>
          </div>
        </div>
      </template>
    </div>
    <pagination class="pagination justify-content-center" v-model="paginate.currentPage" :page-range="3"
      :margin-pages="2" :page-count="paginate.lastPage" :records="paginate.total" :per-page="paginate.perPage"
      :click-handler="submitSearch" :prev-text="prev" :next-text="next" />
  </div>
</template>

<script setup lang="ts">
import { computed, markRaw, onMounted, ref } from 'vue'
import { LocationQuery, RouteParamsRaw, onBeforeRouteUpdate, useLink, useRoute, useRouter } from 'vue-router'
import { useMemberStore } from '@/stores/member'
import { useEventStore } from '@/stores/event'
import { TMember } from '@/types/member'
import { setSeo, toDateSlash, displayReceptionStatus, receptionStatus } from '@/js/util'
import { useField, useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import { TBase, TCategoryType, TEvent, TKindType, TPaginate, TShopType, TTargetType } from '@/types/search'
import Pagination from 'vuejs-paginate-next'
import { TEventParticipant, TEventTarget } from '@/types/event'
import { useLoadingStore } from '@/stores/loading'
import { queryToNumber, queryToNumbersArray, queryToString } from '../../js/route-query-helper'
import { handleErrors } from '@/config/handleErrors'

const route = useRoute()
const router = useRouter()
const memberStore = useMemberStore()

const events = ref<TEvent[]>([])
const paginate = ref<TPaginate>()
const prev = `<span aria-hidden="true"><i class="bi bi-chevron-double-left"></i></span>
              <span class="sr-only">Previous</span>`
const next = `<span aria-hidden="true"><i class="bi bi-chevron-double-right"></i></span>
              <span class="sr-only">Next</span>`

const searchKind = ref(0)
const searchYmca = ref(0)
const searchCategories = ref<number[]>([])
const searchTargets = ref<number[]>([])
const searchPrice = ref(0)
const searchVacancy = ref(0)
const searchSchedules = ref<number[]>([])
const searchBase = ref(0)
const searchShop = ref(0)
const memberId = ref(0)
const paramPage = ref<number | undefined>(0)
// const searchKeyword = ref('')
// const schema = yup.string().max(50, 'キーワードは50文字以内で入力してください')
// const { validate, errors, values } = useForm({ validationSchema: schema });
const schema = yup.object().shape({
  searchKeyword: yup.string().max(50, 'キーワードは50文字以内で入力してください')
})
const { validate, errors, values } = useForm({ validationSchema: schema })
const { value: searchKeyword, errorMessage: keywordError } = useField<string>('searchKeyword', schema)

const kindTypes = ref<TKindType[]>([])
const shopTypes = ref<TShopType[]>([])
const categoryTypes = ref<TCategoryType[]>([])
const targetTypes = ref<TTargetType[]>([])
const baseTypes = ref<TBase[]>([])
const memberIds = ref<number[]>([])
const isSoldOut = ref<boolean>(false)

useLoadingStore().start()
setSeo(route.name?.toString())

onMounted(async () => {
  try {
    const {
      kindTypes: fetchKinds,
      shopTypes: fetchShops,
      categoryTypes: fetchCategories,
      targetTypes: fetchTargets,
      baseTypes: fetchBases,
    }
      = await TEvent.fetchTypes()
    kindTypes.value = fetchKinds
    shopTypes.value = fetchShops
    categoryTypes.value = fetchCategories
    targetTypes.value = fetchTargets
    baseTypes.value = fetchBases
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
})
const getResults = async (page) => {
  // const {
  //   events: fetchEvent,
  //   paginate: fetchPaginate,
  // }
  //   = await TEvent.fetch({ page: page, memberId: useMemberStore().$state.id  })
  // events.value = fetchEvent
  // paginate.value = fetchPaginate
}

const targetRage = (eventTargets: TEventTarget[]): string => {
  eventTargets.sort((a, b) => a.id - b.id)
  const start = eventTargets[0].name
  const end = eventTargets[eventTargets.length - 1].name
  return start.concat('〜').concat(end)
}

// const isFavorite = (event): boolean => {
//   const fav = event.favorites.find(f => 
//     f.memberId === useMemberStore().$state.id && event.id === f.eventId 
//   )
//   return fav ? true : false 
// }

const isFavorite = computed(() => {
  return (event) => {
    const fav = event.favorites.find(f =>
      f.memberId === useMemberStore().$state.id && event.id === f.eventId
    )
    return fav ? true : false;
  }
})

const displayMembership = (event: TEvent) => {
  const memberships = event.memberships.filter(m => {
    return m.pivot.isApplicable
  })
  return memberships
}

const handleFavorite = async (event: TEvent, isFavorite: boolean) => {
  try {
    useLoadingStore().start()
    const response = await TEvent.favorite({ eventId: event.id, isFavorite, page: paginate?.value?.currentPage })
    if (response.data.status === 'failed') {
      alert('お気に入りの更新に失敗しました')
    }
    const {
      events: fetchEvent,
      paginate: fetchPaginate,
      memberIds: fetchMemberIds,
    }
      = await TEvent.search({
        searchKind: searchKind.value,
        searchYmca: searchYmca.value,
        searchCategories: searchCategories.value,
        searchTargets: searchTargets.value,
        searchPrice: searchPrice.value,
        searchVacancy: searchVacancy.value,
        searchSchedules: searchSchedules.value,
        searchKeyword: searchKeyword.value,
        searchBase: searchBase.value,
        searchShop: searchShop.value,
        memberships: useMemberStore().memberships,
        page: paginate?.value?.currentPage,
      })
    events.value = fetchEvent
    paginate.value = fetchPaginate
    memberIds.value = fetchMemberIds
    // await handleSearch(paginate?.value?.currentPage)
    // else {
    //   events.value = (response.data.events as any[]).map(data => new TEvent(data))
    //   paginate.value = new TPaginate(response.data.meta)
    // }
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
}

const isApplied = computed(() => (event: TEvent): boolean => {
  let applyCount = event.eventMember.length
  let family: number[] = []
  if (!event.applicantParent && event.applicantChild && memberIds.value.length === 1) {
    return false
  } else {
    if (event.applicantParent) family.push(...memberIds.value.filter(id => Number(id) === Number(useMemberStore().id)) ) // 親を削除
    if (event.applicantChild) family.push(...memberIds.value.filter(id => Number(id) !== Number(useMemberStore().id)) ) // 親以外を残す
    return applyCount >= family.length * event.eventSchedules.length
  }
})

const isAttend = computed(() => (event: TEvent): boolean => {
  return event.isApply;
}
)

// const receptionStatus = (participants: TEventParticipant[], event?: TEvent) => {
//   if (event) {
//     const now = new Date()
//     const start = new Date(event.recruitmentPeriodStart)
//     const end = new Date(event.recruitmentPeriodEnd)
//     if (!(start < now && now < end)) return ''
//   }
//   if (participants.length > 1) {
//     let isApplicable: string[] = []
//     let isWaiting: string[] = []
//     let isSoldOut: string[] = []
//     participants.forEach(p => {
//       if (p.isApplicable) isApplicable.push('予約する')
//       if (p.waitlistLimit !== null && p.isApplicable == false) {
//         if (p.waitlistCount < p.waitlistLimit) isWaiting.push('キャンセル待ち')
//         if (p.waitlistCount >= p.waitlistLimit) isSoldOut.push('満席')
//       }
//     })
//     if (isSoldOut.length === participants.length) return '満席'
//     if ((isSoldOut.length + isWaiting.length) >= participants.length) return 'キャンセル待ち'
//     if (isApplicable.length === participants.length) {
//       return '受付中'
//     } else {
//       return ''
//     }
//   } else {
//     let isApplicable = true
//     let isWaiting = false
//     let isSoldOut = false
//     participants.forEach(p => {
//       isApplicable = p.isApplicable
//       if (p.waitlistLimit !== null && isApplicable == false) {
//         isWaiting = p.waitlistCount < p.waitlistLimit
//         isSoldOut = p.waitlistCount >= p.waitlistLimit
//       }
//     })
//     if (isSoldOut) return '満席'
//     if (isWaiting) return 'キャンセル待ち'
//     if (isApplicable) {
//       return '受付中'
//     } else {
//       return ''
//     }
//   }
// }

const submitSearch = async (page?: number) => {
  try {
    useLoadingStore().start()
    const valid = await validate()
    if (!valid.valid) return
    if (page == undefined) page = 1
    paramPage.value = page
    updateUrlQueryFromData()
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
}

const handleSearch = async (page?: number) => {
  try {
    useLoadingStore().start()
    const valid = await validate()
    if (!valid.valid) return
    if (page == undefined) page = 1
    paramPage.value = page
    updateUrlQueryFromData()
    const {
      events: fetchEvent,
      paginate: fetchPaginate,
      memberIds: fetchMemberIds,
    }
      = await TEvent.search({
        searchKind: searchKind.value,
        searchYmca: searchYmca.value,
        searchCategories: searchCategories.value,
        searchTargets: searchTargets.value,
        searchPrice: searchPrice.value,
        searchVacancy: searchVacancy.value,
        searchSchedules: searchSchedules.value,
        searchKeyword: searchKeyword.value,
        searchBase: searchBase.value,
        searchShop: searchShop.value,
        memberships: useMemberStore().memberships,
        page: page,
      })
    console.log('Fetch Event:', fetchEvent);
    events.value = fetchEvent
    paginate.value = fetchPaginate
    memberIds.value = fetchMemberIds
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
}

const updateDataFromUrlQuery = (query: LocationQuery): void => {
  searchKind.value = queryToNumber(query.kind) ?? 0
  searchYmca.value = queryToNumber(query.ymca) ?? 0
  searchBase.value = queryToNumber(query.base) ?? 0
  searchCategories.value = queryToNumbersArray(query.categories) ?? []
  searchTargets.value = queryToNumbersArray(query.targets) ?? []
  searchPrice.value = queryToNumber(query.price) ?? 0
  searchVacancy.value = queryToNumber(query.vacancy) ?? 0
  searchSchedules.value = queryToNumbersArray(query.schedules) ?? []
  searchKeyword.value = queryToString(query.keyword) ?? ''
  // memberId.value = queryToNumber(query.member_id) ?? 0
  paramPage.value = queryToNumber(query.page) ?? 0
}

/* ref データを route.query に追加する */
const updateUrlQueryFromData = (): void => {
  router.push({
    query: {
      // URL クエリパラメータでは値がないときに undefined を設定する
      kind: searchKind.value || undefined,
      ymca: searchYmca.value || undefined,
      base: searchBase.value || undefined,
      categories: searchCategories.value || undefined,
      targets: searchTargets.value || undefined,
      price: searchPrice.value || undefined,
      vacancy: searchVacancy.value || undefined,
      schedules: searchSchedules.value || undefined,
      keyword: searchKeyword.value || undefined,
      // member_id: memberId.value || undefined,
      page: paramPage.value || undefined,
    },
    hash: '#results',
  })
}

updateDataFromUrlQuery(route.query)
if (route.query && Object.keys(route.query).length > 0) {
  handleSearch(paramPage.value)
} else {
  events.value = []
}

onBeforeRouteUpdate(async (to, _, next) => {
  updateDataFromUrlQuery(to.query)
  next()
  if (Object.keys(to.query).length > 0) await handleSearch(paramPage.value)
})

const handleClear = (): void => {
  searchKind.value = 0
  searchYmca.value = 0
  searchBase.value = 0
  searchCategories.value = []
  searchTargets.value = []
  searchPrice.value = 0
  searchVacancy.value = 0
  searchSchedules.value = []
  searchKeyword.value = ''
  // memberId.value = queryToNumber(query.member_id) ?? 0
  paramPage.value = 0
  events.value = []
  if (paginate.value) paginate.value = undefined
  router.replace({ query: {} })
  // updateUrlQueryFromData()
  // router.push({ path: route.path, query: {} })
}

</script>
