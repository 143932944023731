import { useMemberStore } from '@/stores/member'
import { API } from '../api'
import { TMember } from './member'
import { TEventParticipant } from './event'

const resource = '/api/search/'

export class TEvent {
  id: number
  path: string
  title: string
  category: number
  content: string
  capacity: number
  displayCapacity: string
  stay: number
  schedule1StartDate: Date
  schedule1EndDate: Date
  schedule2StartDate: Date
  schedule2EndDate: Date
  schedule3StartDate: Date
  schedule3EndDate: Date
  schedule4StartDate: Date
  schedule4EndDate: Date
  schedule5StartDate: Date
  schedule5EndDate: Date
  target: number
  priceDetail: string
  includedInPrice: string
  excludedInPrice: string
  paymentMethod: number
  destination: string
  gatheringDisbanding: string
  manager: string
  other: string
  displayPeriodStart: Date
  displayPeriodEnd: Date
  recruitmentPeriodStart: Date
  recruitmentPeriodEnd: Date
  applicantParent: boolean
  applicantChild: boolean
  item: string
  messageBefore: string
  schedule: string
  scheduleName: string
  messageAfter: string
  images: TImage[]
  categoryType: TCategoryType
  eventTargets: TEventTarget[]
  eventSchedules: TEventSchedule[]
  favorites: TFavorite[]
  memberships: TEventMembership[]
  eventMember: TEventMember[]
  participants: TEventParticipant[]
  isDisplay: boolean
  isApply: boolean
  kindType: TKindType

  constructor(data: any) {
    this.id = Number(data.id) || 0
    this.path = data.path
    this.title = data.title
    this.category = data.category
    this.content = data.content
    this.capacity = Number(data.capacity)
    this.displayCapacity = data.display_capacity
    this.stay = data.stay
    this.schedule1StartDate = data.schedule_1_start_date
    this.schedule1EndDate = data.schedule_1_end_date
    this.schedule2StartDate = data.schedule_2_start_date
    this.schedule2EndDate = data.schedule_2_end_date
    this.schedule3StartDate = data.schedule_3_start_date
    this.schedule3EndDate = data.schedule_3_end_date
    this.schedule4StartDate = data.schedule_4_start_date
    this.schedule4EndDate = data.schedule_4_end_date
    this.schedule5StartDate = data.schedule_5_start_date
    this.schedule5EndDate = data.schedule_5_end_date
    this.target = Number(data.target)
    this.priceDetail = data.price_detail
    this.includedInPrice = data.included_in_price
    this.excludedInPrice = data.excluded_in_price
    this.paymentMethod = Number(data.payment_method)
    this.destination = data.destination
    this.gatheringDisbanding = data.gathering_disbanding
    this.manager = data.manager
    this.other = data.other
    this.displayPeriodStart = data.display_period_start
    this.displayPeriodEnd = data.display_period_end
    this.recruitmentPeriodStart = data.recruitment_period_start
    this.recruitmentPeriodEnd = data.recruitment_period_end
    this.applicantParent = data.applicant_parent
    this.applicantChild = data.applicant_child
    this.item = data.item
    this.messageBefore = data.message_before
    this.schedule = data.schedule
    this.scheduleName = data.schedule_name
    this.messageAfter = data.message_after
    this.categoryType = data.category_type
    this.images = ('images' in data) ? (Object.keys(data.images).map(key => data.images[key]) as any[]).map(d => new TImage(d)) : []
    this.eventTargets = ('target_types' in data) ? (Object.keys(data.target_types).map(key => data.target_types[key]) as any[]).map(d => new TEventTarget(d)) : []
    this.eventSchedules = ('schedules' in data) ? (Object.keys(data.schedules).map(key => data.schedules[key]) as any[]).map(d => new TEventSchedule(d)) : []
    this.favorites = ('favorites' in data) ? (Object.keys(data.favorites).map(key => data.favorites[key]) as any[]).map(d => new TFavorite(d)) : []
    this.memberships = ('membership_types' in data) ? (Object.keys(data.membership_types).map(key => data.membership_types[key]) as any[]).map(d => new TEventMembership(d)) : []
    this.eventMember = ('event_member' in data) ? (Object.keys(data.event_member).map(key => data.event_member[key]) as any[]).map(d => new TEventMember(d)) : []
    this.participants = ('participants' in data) ? (Object.keys(data.participants).map(key => data.participants[key]) as any[]).map(d => new TEventParticipant(d)) : []
    this.isDisplay = data.is_display
    this.isApply = data.is_apply
    this.kindType = data.kind_type
  }

  static fetchTypes() {
    return API.get(resource.concat('index')).then(r => {
      return {
        kindTypes: (r.data.kind_types as any[]).map(data => new TKindType(data)),
        shopTypes: (r.data.shop_types as any[]).map(data => new TShopType(data)),
        categoryTypes: (r.data.category_types as any[]).map(data => new TCategoryType(data)),
        targetTypes: (r.data.target_types as any[]).map(data => new TTargetType(data)),
        baseTypes: (r.data.base_types as any[]).map(data => new TBase(data)),
      }
    })
  }

  static fetch({ page }: { page?: number }) {
    const params = { page: page ? page : 1 }
    return API.get(resource.concat('search'), { params }).then((r) => {
      return {
        events: (r.data.events as any[]).map(data => new TEvent(data)),
        paginate: new TPaginate(r.data.meta),
      }
    })
  }

  static fetchFavorite({ page }: { page?: number }) {
    const params = { page: page ? page : 1 }
    return API.get('/api/mypage/'.concat('favorite'), { params }).then((r) => {
      return {
        // favoriteEvents: (r.data.favoriteEvents.data as any[]).map(data => new TEvent(data['event'])),
        favoriteEvents: (r.data.favoriteEvents.data as any[])
          .filter(data => data['event'] !== null)
          .map(data => new TEvent(data['event'])),
        paginate: new TPaginate(r.data.meta),
        memberIds:r.data.memberIds
      }
    })
  }

  static async favorite({ eventId: eventId, isFavorite: isFavorite, page: page }) {
    const params = { event_id: eventId, is_favorite: isFavorite, page: page }
    return await API.put(resource.concat('favorite'), params)
  }

  static async search({ searchKind, searchYmca, searchCategories, searchTargets, searchPrice, searchVacancy, searchSchedules, searchKeyword, searchBase, memberships, page }: {
    searchKind: number,
    searchYmca: number,
    searchCategories: number[],
    searchTargets: number[],
    searchPrice: number,
    searchVacancy: number,
    searchSchedules: number[],
    searchKeyword: string,
    searchBase: number,
    searchShop: number,
    memberships: number[],
    page: number | undefined
  }) {
    const params = {
      kind: searchKind,
      ymca: searchYmca,
      categories: searchCategories,
      targets: searchTargets,
      price: searchPrice,
      vacancy: searchVacancy,
      schedules: searchSchedules,
      keyword: searchKeyword,
      base: searchBase,
      memberships: memberships,
      page: page
    }
    return API.get(resource.concat('search'), { params }).then((r) => {
      return {
        events: ('events' in r.data) && r.data.events.length > 0 ? (r.data.events as any[]).map(data => new TEvent(data)) : [],
        paginate: ('meta' in r.data) ? new TPaginate(r.data.meta) : undefined,
        memberIds: r.data.member_ids as number[],
      }
    })
    //   if (response.data.status === 'failed') {
    //     alert(response.data.message);
    //     return 'failed'
    //   }
    //   console.log({response})
    //   return 'success'
    //     // return new TEvent(r.data.event)
    // }
  }
}

export class TPaginate {
  currentPage: number
  firstPageUrl: string
  from: number
  lastPage: number
  lastPageUrl: number
  links: TLink[]
  nextPageUrl: string
  path: string
  perPage: number
  prevPageUrl: string | null
  to: number
  total: number
  constructor(data: any) {
    this.currentPage = data.current_page
    this.firstPageUrl = data.first_page_url
    this.from = data.from
    this.lastPage = data.last_page
    this.lastPageUrl = data.last_page_url
    this.links = data.links
    this.nextPageUrl = data.next_page_url
    this.path = data.path
    this.perPage = data.per_page
    this.prevPageUrl = data.prev_page_url
    this.to = data.to
    this.total = data.total
  }
  static async page({ id, page }: {
    id: number,
    page: number | undefined
  }) {
    const params = {
      id: id,
      page: page
    }
    try {
      return API.post("/api/mypage/schedule", params).then((r) => {
        return {
          paginate: new TPaginate(r.data.meta),
          allSchedules: r.data.allSchedules.data
        }
      })
    } catch(e) {
      throw e
    }
  }
}

export class TLink {
  url: string
  label: string
  active: boolean
  constructor(data: any) {
    this.url = data.url
    this.label = data.label
    this.active = data.active
  }
}

export class TFavorite {
  id: number
  eventId: number
  memberId: number
  constructor(data: any) {
    this.id = data.id
    this.eventId = data.pivot.event_id
    this.memberId = data.pivot.member_id
  }
}

export class TEventMember {
  id: number
  eventId: number
  eventCode: number
  memberId: number
  isWaiting: boolean
  reservedAt: Date
  fixedAt: Date
  constructor(data: any) {
    this.id = data.id
    this.eventId = data.event_id
    this.eventCode = data.event_code
    this.memberId = data.member_ids
    this.isWaiting = data.is_waiting
    this.reservedAt = data.reserved_at
    this.fixedAt = data.fixed_at
  }
}

export class TKindType {
  id: number
  name: string
  constructor(data: any) {
    this.id = data.id
    this.name = data.name
  }
}

export class TShopType {
  id: number
  name: string
  isDisplay: boolean
  constructor(data: any) {
    this.id = data.id
    this.name = data.name
    this.isDisplay = data.is_display
  }
}

export class TTargetType {
  id: number
  groupId: number
  name: string
  constructor(data: any) {
    this.id = data.id
    this.groupId = data.group_id
    this.name = data.name
  }
}

export class TCategoryType {
  id: number
  name: string
  constructor(data: any) {
    this.id = data.id
    this.name = data.name
  }
}


////////////////////////////////////////////////////////////////////////

export class TEventTarget {
  id: number
  name: string
  groupId: number
  pivot: {}

  constructor(data: any) {
    this.id = data.id,
      this.name = data.name,
      this.groupId = data.group_id,
      this.pivot = data.pivot
  }
}

export class TEventMembership {
  id: number
  name: string
  pivot: {
    isApplicable: boolean,
    isViewable: boolean
  }

  constructor(data: any) {
    this.id = data.id,
      this.name = data.name,
      // this.pivot = data.pivot
      this.pivot = {
        isApplicable: data.pivot.is_applicable,
        isViewable: data.pivot.is_viewable
      }
  }
}

export class TEventSchedule {
  id: number
  eventId: number
  eventCode: string
  startAt: Date
  endAt: Date
  isChecked: boolean

  constructor(data: any) {
    this.id = data.id
    this.eventId = data.event_id
    this.eventCode = data.event_code
    this.startAt = data.start_at
    this.endAt = data.end_at
    this.isChecked = false
  }
}

export class TPaymentMethod {
  id: number
  typeId: number
  name: string
  constructor(data: any) {
    this.id = data.id
    this.typeId = data.type_id
    this.name = data.name
  }
}

export class TChoice {
  id: number
  surveyId: number
  label: string
  value: number
  constructor(data: any) {
    this.id = data.id
    this.surveyId = data.survey_id
    this.label = data.label
    this.value = data.value
  }
}

export class TAnswer {
  id: number
  surveyId: number
  memberId: number
  choiceId: number | null
  textAnswer: string | null
  constructor(data: any) {
    this.id = data.id
    this.surveyId = data.survey_id
    this.memberId = data.member_id
    this.choiceId = data.choice_id
    this.textAnswer = data.text_answer
  }

  static fromData(id: number, surveyId: number, memberId: number, choiceId: number | null, textAnswer: string | null): TAnswer {
    return new TAnswer({ id, survey_id: surveyId, member_id: memberId, choice_id: choiceId, text_answer: textAnswer })
  }
}

export class TSurvey {
  id: number
  type: number
  content: string
  choices: TChoice[]
  constructor(data: any) {
    this.id = data.id
    this.type = data.type
    this.content = data.content
    this.choices = ('choices' in data) ? (Object.keys(data.choices).map(key => data.choices[key]) as any[]).map(d => new TChoice(d)) : []
  }
}

export class TRental {
  id: number
  memberId: number
  member: TMember
  choices: TChoice[]
  answers: TAnswer[]
  constructor(data: any) {
    this.id = data.id
    this.memberId = data.member_id
    this.member = data.member
    this.choices = data.choices
    // this.choices = [...data.choices]
    this.answers = data.answers
  }
  static fromData(id: number, memberId: number, member: TMember, choices: TChoice[] | null, answers: TAnswer[] | null): TRental {
    return new TRental({ id: id, member_id: memberId, member: member, choices: choices, answers: answers })
  }
  getTotalChoicesValue(): number {
    let total = 0
    for (const choice of this.choices) {
      total += choice.value;
    }
    return total
  }
}

export class TBase {
  id: number
  name: string
  mailAddress: string
  url: string
  phoneNumber: string
  isDisplay: boolean
  constructor(data: any) {
    this.id = data.id
    this.name = data.name
    this.mailAddress = data.mail_address
    this.url = data.url
    this.phoneNumber = data.phone_number
    this.isDisplay = data.is_display
  }
}

export class TImage {
  id: number
  eventId: number
  url: string
  name: string
  originalName: string

  constructor(data: any) {
    this.id = data.id
    this.eventId = data.event_id
    this.url = data.url
    this.name = data.name
    this.originalName = data.original_name
  }
}
